import { useMemo } from 'react'
import { observer } from 'mobx-react'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Form, Input, Row, Select } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import classnames from 'classnames/bind'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { formatDate } from '@/utils/date'

import styles from './AddVideo.module.scss'

const cn = classnames.bind(styles)

const SelectOptions = ({ name, createdAt, isMyLesson }: { name: string; createdAt: string; isMyLesson: boolean }) => (
  <div className={cn('add-video__lesson')}>
    <span className={cn('add-video__lesson-title')}>{name}</span>
    <span> ({formatDate(createdAt)})</span>
    {isMyLesson && <span>©️</span>}
  </div>
)

type Props = {
  file: UploadFile
  onDelete?: () => void
  isCurriculum?: boolean
}

export const Items = observer(({ file, onDelete, isCurriculum }: Props) => {
  const { groupsStore } = useStores()
  const fileName = file.name
  const uid = file.uid

  const lessons = useMemo(
    () =>
      groupsStore.lessons?.lessons.map((item) => ({
        label: <SelectOptions isMyLesson={item.isMyLesson} name={item.name} createdAt={item.createdAt} />,
        value: item.id,
      })),
    [groupsStore.lessons],
  )

  return (
    <Row className={cn('add-video__item')} gutter={16}>
      <Col className="gutter-row" span={isCurriculum ? 10 : 6}>
        <Form.Item label="File name" rules={[{ required: true, message: mainDictionary.requiredLesson }]}>
          {fileName}
        </Form.Item>
      </Col>
      {!isCurriculum && (
        <Col className="gutter-row" span={8}>
          <Form.Item
            label={mainDictionary.lesson}
            name={[uid, 'lessonId']}
            rules={[{ required: true, message: mainDictionary.requiredLesson }]}
          >
            <Select options={lessons} placeholder={mainDictionary.selectLesson} />
          </Form.Item>
        </Col>
      )}

      <Col className="gutter-row" span={8}>
        <Form.Item
          label={mainDictionary.videoName}
          rules={[
            { required: true, message: mainDictionary.requiredLessonName },
            { max: 500, message: mainDictionary.maxLessonNameCharacter },
          ]}
          name={[uid, 'origName']}
          initialValue={fileName}
        >
          <Input defaultValue={fileName} />
        </Form.Item>
      </Col>

      {onDelete && (
        <Col className="gutter-row" span={6}>
          <Flex justify="flex-end">
            <Form.Item label="Actions">
              <Button icon={<DeleteOutlined />} shape="circle" size="large" onClick={onDelete} />
            </Form.Item>
          </Flex>
        </Col>
      )}
    </Row>
  )
})
