import { makeAutoObservable } from 'mobx'
import { DetailedError } from 'tus-js-client'
import { IRoles } from '@/api/controls'
import { groupsApi, IGetHomeworkStatisticsByStudentRes, IHomeworkResponse, StaffGroupsDetail } from '@/api/groups'
import {
  ChapterHomework,
  ChapterHomeworkRes,
  CourseChapter,
  CourseChapterRes,
  CurriculumVideo,
  GetHomeworkDbCoursesRes,
  GetHomeworkStatisticByCheckingRes,
  GetHomeworkStatisticsRes,
  GetTeachersStatisTicsRes,
  HomeworkDbCourses,
  ICourse,
  ICourseResponse,
  ICurriculum,
  IDiscipline,
  IFilterObjectsResponse,
  IGetCourseCurriculumsRes,
  IGetCourseResponse,
  IHomeworkStatisticGroupsRes,
  IOption,
  MasterClass,
  MasterClassesRes,
  SingleCurriculum,
} from '@/api/study-department'
import { studyDepartmentApi } from '@/api/study-department/study-department'
import mainDictionary from '@/dictionary'
import { api } from '@/modules/kinescope/api'
import { uploadToKinescopeFile } from '@/modules/kinescope/utils'
import { addCatchNotification } from '@/modules/notifications'
import { allOption } from '@/pages/Groups/constants'
import { HomeworkStatistics } from '@/pages/Groups/types'
import { CourseTabs, ICourseFormValues } from '@/pages/StudyDepartment/types'
import { appStore } from '../app'
import { IGetRolesParams } from '../controls/type'
import { IGetHomeworks, IGetStudentHomeworkStatistics, ITextAreaState } from '../groups/types'
import {
  AddChapterHomeworkReq,
  AddChapterReq,
  AddCourseParams,
  EditChapterHomeworkReq,
  EditChapterReq,
  EditCourseParams,
  EditDraftParams,
  EditVideoCourseParams,
  GetChapterHomeworkReq,
  GetCourseChaptersReq,
  GetHomeworkDbCoursesReq,
  GetMasterClassesReq,
  GetTeachersStatisTicsReq,
  GetUploadLinkParams,
  IAddCurriculum,
  IChangePagination,
  IChangeTopicPositionRequest,
  IEditCourse,
  IEditCourseByMonth,
  IGetCourseCurriculums,
  IGetCourseRequest,
  IGetFilterObjects,
  IGetGroupRequest,
  IGetGroupStudentsRequest,
  IGetHomeworkStudents,
  ISelectOption,
  MoveToCompletionParams,
  PublicStudentVideoTypes,
  SetLessonReq,
  SwitchHomeworkParams,
  TGetGroupActionsFilterObjects,
  UploadFile,
  UploadVideoParams,
  VideoStatus,
} from './types'

class StudyDepartment {
  homeworkStatisticGroups: IHomeworkStatisticGroupsRes | null = null
  groupStudents: GetHomeworkStatisticsRes | null = null
  studentStatistics: IGetHomeworkStatisticsByStudentRes | null = null
  homeworksByChecking: GetHomeworkStatisticByCheckingRes | null = null
  homeworksByDoneFilter: IGetGroupStudentsRequest | null = null
  studentFilter: IGetGroupStudentsRequest | null = null
  homeworkByCheckingFilter: IGetGroupStudentsRequest | null = null
  homeworkGroupsFilter: IGetGroupRequest | null = null
  filterObjects: IFilterObjectsResponse | null = null
  groupTeachers: StaffGroupsDetail | null = null
  teachersModal = false
  teachers: ISelectOption[] | null = null
  courses: ISelectOption[] | null = null
  learningTypes: ISelectOption[] | null = null
  courseMake = false
  studyMonthEditModal = false
  addTopic = false
  allCourses: IGetCourseResponse | null = null
  disciplines: IDiscipline[] | null = null
  courseLoading = false
  singleCourse: ICourse | null = null
  course: ICourseResponse | null = null
  courseCurriculum: IGetCourseCurriculumsRes | null = null
  curriculumLoading = false
  homeworkPerPage: number | null = null
  singleCurriculum: ICurriculum | null = null
  coursesPagination = 1
  coursePerPage = 100
  tableLoading = false
  editDraft = false
  uploadingPedagogicalVideos: MasterClass[] = []
  uploadingTechnicalVideos: MasterClass[] = []
  courseActiveTab: CourseTabs | string = CourseTabs.LessonMonth
  homeworkTextAreaState: ITextAreaState | null = null
  teacherStatisticsFilterLoading = false

  teacherStatisticsFilter: GetTeachersStatisTicsReq = {
    page: 1,
    perPage: 10,
  }

  curriculumPagination = {
    page: 1,
    perPage: 100,
  }

  allHomeworks: IHomeworkResponse | null = null
  categories: ISelectOption[] | null = null
  homeworkStatisticsSort: HomeworkStatistics | null = null
  addMasterclassModal = false

  roles: IRoles[] | null = null
  singleHomeDefaultTabKey = '1'
  actionCourses: ISelectOption[] = []
  actionLearningTypes: ISelectOption[] = []
  actionOffices: ISelectOption[] = []
  actionAcademics: ISelectOption[] = []
  chapterMakeModal = false
  homeworkDbCourses: GetHomeworkDbCoursesRes | null = null
  chapters: CourseChapterRes | null = null
  chaptersLoading = false
  selectedChapter: CourseChapter | null = null
  chapterHomework: ChapterHomeworkRes | null = null
  chapterHomeworkLoading = false
  connectHomeworkModal = false
  homeworkDbCoursesLoading = false
  makeCourse = false
  teachersStatistics: GetTeachersStatisTicsRes | null = null
  selectedHomework: ChapterHomework | null = null
  selectedHomeworkDbCourse: HomeworkDbCourses | null = null
  selectedCurriculum: ICurriculum | null = null
  singleHomeworkCurriculum: SingleCurriculum | null = null
  masterClassesLoading = false
  videoHistoryModal = false
  selectedMasterclass: MasterClass | null = null
  editMasterClassModal = false
  makeVideoModal = false
  curriculum: ICurriculum | null = null
  curriculumVideoLoading = false
  editVideoModal = false
  curriculumVideoHistoryModal = false
  curriculumVideoModal = false
  uploadingCurriculum: ICurriculum | null = null
  selectedVideo: CurriculumVideo | null = null
  videoModal = false
  selectedMasterClass: MasterClass | null = null
  activeMasterClass = PublicStudentVideoTypes.Pedagogical
  masterClassesFilter: GetMasterClassesReq = {
    page: 1,
    perPage: 10,
  }

  technicalMasterClasses: MasterClassesRes | null = null
  pedagogicalMasterClasses: MasterClassesRes | null = null
  chapterHomeworkFilter: GetChapterHomeworkReq = {
    page: 1,
    perPage: 10,
  }

  chaptersPagination: GetCourseChaptersReq = {
    page: 1,
    perPage: 10,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setVideoModal = (params: boolean) => {
    this.videoModal = params
  }

  setVideoHistoryModal = (params: boolean) => {
    this.videoHistoryModal = params
  }

  setSelectedMasterclass = (params: MasterClass | null) => {
    this.selectedMasterclass = params
  }

  setUploadingPedagogicalVideos = (params: MasterClass[]) => {
    this.uploadingPedagogicalVideos = params
  }

  setUploadingTechnicalVideos = (params: MasterClass[]) => {
    this.uploadingTechnicalVideos = params
  }

  setEditMasterClassModal = (params: boolean) => {
    this.editMasterClassModal = params
  }

  setMakeVideoModal = (params: boolean) => {
    this.makeVideoModal = params
  }

  setCurriculum = (params: ICurriculum | null) => {
    this.curriculum = params
  }

  setCurriculumVideoModal = (params: boolean) => {
    this.curriculumVideoModal = params
  }

  setSelectedVideo = (params: CurriculumVideo | null) => {
    this.selectedVideo = params
  }

  setCurriculumVideoHistoryModal = (params: boolean) => {
    this.curriculumVideoHistoryModal = params
  }

  setEditVideoModal = (params: boolean) => {
    this.editVideoModal = params
  }

  setCurriculumVideoLoading = (params: boolean) => {
    this.curriculumVideoLoading = params
  }

  setUploadingCurriculum = (params: ICurriculum | null) => {
    this.uploadingCurriculum = params
  }

  setSelectedMasterClass = (params: MasterClass | null) => {
    this.selectedMasterClass = params
  }

  setMasterClassesLoading = (params: boolean) => {
    this.masterClassesLoading = params
  }

  setTechnicalMasterClasses = (params: MasterClassesRes | null) => {
    this.technicalMasterClasses = params
  }

  setAddMasterclassModal = (params: boolean) => {
    this.addMasterclassModal = params
  }

  setPedagogicalMasterClasses = (params: MasterClassesRes | null) => {
    this.pedagogicalMasterClasses = params
  }

  setActiveMasterClass = (params: PublicStudentVideoTypes) => {
    this.activeMasterClass = params
  }

  setMasterClassesFilter = (params: GetMasterClassesReq) => {
    this.masterClassesFilter = params
  }

  setTeacherStatisticsFilterLoading = (params: boolean) => {
    this.teacherStatisticsFilterLoading = params
  }

  setTeacherStatisticsFilter = (params: GetTeachersStatisTicsReq) => {
    this.teacherStatisticsFilter = params
  }

  setSelectedHomework = (params: ChapterHomework | null) => {
    this.selectedHomework = params
  }

  setSingleHomeworkCurriculum = (params: SingleCurriculum | null) => {
    this.singleHomeworkCurriculum = params
  }

  setSelectedHomeworkDbCourse = (params: HomeworkDbCourses | null) => {
    this.selectedHomeworkDbCourse = params
  }

  setMakeCourse = (params: boolean) => {
    this.makeCourse = params
  }

  setEditDraft = (params: boolean) => {
    this.editDraft = params
  }

  setHomeworkDbCoursesLoading = (params: boolean) => {
    this.homeworkDbCoursesLoading = params
  }

  setSelectedCurriculumId = (params: ICurriculum | null) => {
    this.selectedCurriculum = params
  }

  setHomeworkDbCourses = (params: GetHomeworkDbCoursesRes | null) => {
    this.homeworkDbCourses = params
  }

  setConnectHomeworkModal = (params: boolean) => {
    this.connectHomeworkModal = params
  }

  setChapterHomeworkFilter = (params: GetChapterHomeworkReq) => {
    this.chapterHomeworkFilter = params
  }

  setChapterHomework = (params: ChapterHomeworkRes | null) => {
    this.chapterHomework = params
  }

  setChapterHomeworkLoading = (params: boolean) => {
    this.chapterHomeworkLoading = params
  }

  setSelectedChapter = (params: CourseChapter | null) => {
    this.selectedChapter = params
  }

  setChapterPagination = (params: GetCourseChaptersReq) => {
    this.chaptersPagination = params
  }

  setChapterMakeModal = (params: boolean) => {
    this.chapterMakeModal = params
  }

  setTeacherStatistics = (params: GetTeachersStatisTicsRes | null) => {
    this.teachersStatistics = params
  }

  setHomeworkPerPage = (params: number) => {
    this.homeworkPerPage = params
  }

  resetStudentStatistics = () => {
    this.studentStatistics = null
  }

  setSingleHomeDefaultTabKey = (params: string) => {
    this.singleHomeDefaultTabKey = params
  }

  resetAllHomeworks = () => {
    this.allHomeworks = null
  }

  setTableLoading = (params: boolean) => {
    this.tableLoading = params
  }

  addHomeworkDbCourse = (params: AddCourseParams) => studyDepartmentApi.addHomeworkDbCourse(params)

  setCoursePerPage = (params: number) => {
    this.coursePerPage = params
  }

  setAllHomeworks = (params: IHomeworkResponse | null) => {
    this.allHomeworks = params
  }

  getUploadVideoLink = (params: GetUploadLinkParams) => studyDepartmentApi.getUploadParams(params)

  getHomeworkChats = (params: IGetHomeworks) =>
    groupsApi
      .getHomeworkForAdmin(params)
      .then((res) => {
        if (res.success) {
          res.data.homeworkChatsData.homeworkChats.reverse()

          this.setAllHomeworks(res.data)
        }
      })
      .catch(addCatchNotification)

  resetFilters = () => {
    this.homeworkGroupsFilter = null
  }

  setCoursesPagination = (params: number) => {
    this.coursesPagination = params
  }

  setHomeworkState = (params: ITextAreaState | null) => {
    this.homeworkTextAreaState = params
  }

  setCourseActiveTab = (params: CourseTabs | string) => {
    this.courseActiveTab = params
  }

  setCurriculumPagination = (params: IChangePagination) => {
    this.curriculumPagination = params
  }

  setChapters = (params: CourseChapterRes | null) => {
    this.chapters = params
  }

  setCourseLoading = (params: boolean) => {
    this.courseLoading = params
  }

  setSingleCurriculum = (params: ICurriculum | null) => {
    this.singleCurriculum = params
  }

  setCurriculumLoading = (params: boolean) => {
    this.curriculumLoading = params
  }

  setSingleCourse = (params: ICourse | null) => {
    this.singleCourse = params
  }

  setAddTopic = (params: boolean) => {
    this.addTopic = params
  }

  setStudyMonthEditModal = (params: boolean) => {
    this.studyMonthEditModal = params
  }

  setCourseMake = (params: boolean) => {
    this.courseMake = params
  }

  resetGroupTeachers = () => {
    this.groupTeachers = null
  }

  setTeacherModal = (params: boolean) => {
    this.teachersModal = params
  }

  setHomeworkStatisticsSort = (params: HomeworkStatistics | null) => {
    this.homeworkStatisticsSort = params
  }

  setHomeworksByDoneFilter = (params: IGetGroupStudentsRequest | null) => {
    this.homeworksByDoneFilter = params
  }

  setChaptersLoading = (params: boolean) => {
    this.chaptersLoading = params
  }

  setHomeworkGroupsFilter = (params: IGetGroupRequest | null) => {
    this.homeworkGroupsFilter = params
  }

  setHomeworkByChecking = (params: IGetGroupStudentsRequest | null) => {
    this.homeworkByCheckingFilter = params
  }

  setStudentFilter = (params: IGetGroupStudentsRequest | null) => {
    this.studentFilter = params
  }

  changeTopicPosition = (params: IChangeTopicPositionRequest) => studyDepartmentApi.changeTopicPosition(params)

  editChapter = (params: EditChapterReq) => studyDepartmentApi.editChapter(params)

  getChapterHomework = (params: GetChapterHomeworkReq) =>
    studyDepartmentApi
      .getChapterHomework(params)
      .then((res) => {
        if (res.success) {
          this.setChapterHomework(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setChapterHomeworkLoading(false))

  getCurriculum = (id: number) =>
    studyDepartmentApi
      .getCurriculum(id)
      .then((res) => {
        if (res.success) {
          const uploadingVideoIds = this.uploadingCurriculum?.videos?.map((item) => item.id)

          res.data?.videos?.forEach((item) => {
            if (uploadingVideoIds?.includes(item.id)) {
              const newUploadingVideos = this.uploadingCurriculum?.videos?.filter((video) => video.id !== item.id)

              this.setUploadingCurriculum({
                ...this.uploadingCurriculum!,
                videos: newUploadingVideos || [],
              })
            }
          })

          this.setCurriculum(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setCurriculumVideoLoading(false))

  editHomeworkDdbCourse = (params: EditCourseParams) => studyDepartmentApi.editHomeworkDdbCourse(params)

  getHomework = (params: number) => studyDepartmentApi.getHomework(params)

  addHomeworkChapter = (params: AddChapterHomeworkReq) => studyDepartmentApi.addHomeworkChapter(params)

  editHomeworkChapter = (params: EditChapterHomeworkReq) => studyDepartmentApi.editHomeworkChapter(params)

  getCourseCurriculums = (params: IGetCourseCurriculums) =>
    studyDepartmentApi
      .getCourseCurriculums(params)
      .then((res) => {
        if (res.success) {
          this.courseCurriculum = res.data
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.curriculumLoading = false
      })

  getMasterClasses = (params: GetMasterClassesReq) =>
    studyDepartmentApi
      .getMasterClasses(params)
      .then((res) => {
        if (res.success) {
          if (this.activeMasterClass === PublicStudentVideoTypes.Pedagogical) {
            const uploadingIds = this.uploadingPedagogicalVideos.map((item) => item.id)

            res.data?.videos?.forEach((item) => {
              if (uploadingIds.includes(item.id)) {
                const newUploadingVideos = this.uploadingPedagogicalVideos.filter((video) => video.id !== item.id)

                this.setUploadingPedagogicalVideos(newUploadingVideos)
              }
            })

            this.setPedagogicalMasterClasses(res.data)

            return
          }

          const uploadingVideos = this.uploadingTechnicalVideos.map((item) => item.id)

          res.data?.videos?.forEach((item) => {
            if (uploadingVideos.includes(item.id)) {
              const newUploadingVideos = this.uploadingTechnicalVideos.filter((video) => video.id !== item.id)

              this.setUploadingTechnicalVideos(newUploadingVideos)
            }
          })

          this.setTechnicalMasterClasses(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setMasterClassesLoading(false))

  getTeacherStatistics = (params: GetTeachersStatisTicsReq) =>
    studyDepartmentApi
      .getTeacherStatistics(params)
      .then((res) => {
        if (res.success) {
          this.setTeacherStatistics(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setTeacherStatisticsFilterLoading(false))

  getStudyRole = (params?: IGetRolesParams) =>
    studyDepartmentApi
      .getStudyRole(params)
      .then((res) => {
        if (res.success) {
          this.roles = res.data
        }
      })
      .catch(addCatchNotification)

  deleteCurriculum = (id: number) => studyDepartmentApi.deleteCurriculum(id)

  addCurriculum = (params: IAddCurriculum) => studyDepartmentApi.addCurriculum(params)

  editCurriculum = (params: IAddCurriculum) => studyDepartmentApi.editCurriculum(params)

  getDisciplines = () =>
    studyDepartmentApi
      .getDisciplines()
      .then((res) => {
        this.disciplines = res.data
      })
      .catch(addCatchNotification)

  addCourse = (params: ICourseFormValues) => studyDepartmentApi.addCourse(params)

  editCourse = (params: IEditCourse) => studyDepartmentApi.editCourse(params)

  getGroups = (params?: IGetGroupRequest) =>
    studyDepartmentApi
      .getHomeworkStatistics(params)
      .then((res) => {
        if (res.success) {
          this.homeworkStatisticGroups = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => (this.tableLoading = false))

  setActionCourses = (params: ISelectOption[]) => {
    this.actionCourses = params
  }

  deleteHomework = (params: number) => studyDepartmentApi.deleteHomework(params)

  setActionAcademics = (params: ISelectOption[]) => {
    this.actionAcademics = params
  }

  setActionLearningTypes = (params: ISelectOption[]) => {
    this.actionLearningTypes = params
  }

  setActionOffices = (params: ISelectOption[]) => {
    this.actionOffices = params
  }

  getGroupActionsFilterObjects = (params: TGetGroupActionsFilterObjects) =>
    studyDepartmentApi.getGroupActionsFilterObjects(params).then((res) => {
      if (res.success) {
        const courses = res.data?.courses?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        const academics = res.data?.academics?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        const offices = res.data?.offices?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        const learningTypes = res.data?.learningTypes?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        this.setActionCourses(courses)
        this.setActionAcademics(academics)
        this.setActionOffices([allOption, ...offices])
        this.setActionLearningTypes([allOption, ...learningTypes])
      }
    })

  editMasterClass = (params: EditVideoCourseParams) => studyDepartmentApi.editMasterClass(params)

  deleteMasterClass = (id: number) => studyDepartmentApi.deleteMasterClass(id)

  setGroupStudents = (params: GetHomeworkStatisticsRes | null) => {
    this.groupStudents = params
  }

  setLessonToCurriculum = (params: SetLessonReq) => studyDepartmentApi.setLessonToCurriculum(params)

  switchLessonToCurriculum = (params: SwitchHomeworkParams) => studyDepartmentApi.switchLessonToCurriculum(params)

  getCourseChapters = (params: GetCourseChaptersReq) =>
    studyDepartmentApi
      .getCourseChapters(params)
      .then((res) => {
        if (res.success) {
          this.setChapters(res.data)
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setChaptersLoading(false)
      })

  getSingeCurriculum = (id: number) =>
    studyDepartmentApi
      .getSingeCurriculum(id)
      .then((res) => {
        if (res.success) {
          this.setSingleHomeworkCurriculum(res.data)
        }
      })
      .catch(addCatchNotification)

  editDraftComplete = (params: EditDraftParams) => studyDepartmentApi.editDraft(params)

  getHomeworkDbCourses = (params: GetHomeworkDbCoursesReq) =>
    studyDepartmentApi
      .getHomeworkDbCourses(params)
      .then((res) => {
        if (res.success) {
          this.setHomeworkDbCourses(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setHomeworkDbCoursesLoading(false))

  getGroupStudents = (params: IGetGroupStudentsRequest) =>
    studyDepartmentApi
      .getGroupStudents(params)
      .then((res) => {
        if (res.success) {
          this.groupStudents = res.data
        }
      })
      .catch(addCatchNotification)

  getGroupHomeworkStudents = (params: IGetHomeworkStudents) =>
    studyDepartmentApi
      .getHomeworksByChecking(params)
      .then((res) => {
        if (res.success) {
          this.homeworksByChecking = res?.data
        }
      })
      .catch(addCatchNotification)

  getSingleStudentHomework = (params: IGetStudentHomeworkStatistics) =>
    groupsApi
      .getStudentGroupStatistics(params)
      .then((res) => {
        if (res.success) {
          this.studentStatistics = res?.data
        }
      })
      .catch(addCatchNotification)

  getCourse = (id: number) =>
    studyDepartmentApi
      .getCourse(id)
      .then((res) => {
        if (res.success) {
          this.course = res.data
        }

        return res
      })
      .catch(addCatchNotification)

  editCourseMyMonth = (params: IEditCourseByMonth) => studyDepartmentApi.editCourseByMonth(params)

  deleteCourse = (params: number) => studyDepartmentApi.deleteCourse(params)

  getCourses = (params?: IGetCourseRequest) =>
    studyDepartmentApi
      .getCourses(params)
      .then((res) => {
        if (res.success) {
          this.allCourses = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setCourseLoading(false)
      })

  resetCourses = () => {
    this.allCourses = null
  }

  optionFormatter = (options: IOption[], isSingle?: boolean) => {
    const formattedOptions = options.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    if (isSingle) {
      return formattedOptions
    }

    return [allOption, ...formattedOptions]
  }

  moveToCompletion = (params: MoveToCompletionParams) => studyDepartmentApi.moveToCompletion(params)

  private getHandleUploadProgress =
    (id: number, type: PublicStudentVideoTypes) => (bytesUploaded: number, bytesTotal: number, percentage: number) => {
      const updatedVideos = (
        type === PublicStudentVideoTypes.Pedagogical ? this.uploadingPedagogicalVideos : this.uploadingTechnicalVideos
      )?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            percentage,
          }
        }

        return item
      })

      if (type === PublicStudentVideoTypes.Pedagogical) {
        this.setUploadingPedagogicalVideos(updatedVideos)
      } else {
        this.setUploadingTechnicalVideos(updatedVideos)
      }
    }

  getFilterObjects = (params?: IGetFilterObjects) =>
    studyDepartmentApi
      .getFilterObjects(params)
      .then((res) => {
        if (res.success) {
          this.filterObjects = res.data
          this.courses = this.optionFormatter(res.data?.courses)
          this.learningTypes = this.optionFormatter(res?.data?.learningTypes)
          this.teachers = this.optionFormatter(res?.data?.teachers, true)
          this.categories = this.optionFormatter(res?.data?.categories)
        }
      })
      .catch(addCatchNotification)

  getGroupTeachers = (params: number) =>
    studyDepartmentApi
      .getGroupTeachers(params)
      .then((res) => {
        if (res.success) {
          this.groupTeachers = res.data
          this.setTeacherModal(true)
        }
      })
      .catch(addCatchNotification)

  addChapter = (params: AddChapterReq) => studyDepartmentApi.addChapter(params)

  private getHandleUploadError = (id: number, type: PublicStudentVideoTypes) => (error: DetailedError | Error) => {
    const updatedVideos = (
      type === PublicStudentVideoTypes.Pedagogical ? this.uploadingPedagogicalVideos : this.uploadingTechnicalVideos
    )?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          currentStatus: VideoStatus.Error,
        }
      }

      return item
    })

    if (type === PublicStudentVideoTypes.Pedagogical) {
      this.setUploadingPedagogicalVideos(updatedVideos)
    } else {
      this.setUploadingTechnicalVideos(updatedVideos)
    }

    appStore.sendLog({
      text: `
          *error*: ${error?.message || error?.stack || ''}
        `,
    })
  }

  updateVideoStatus = (params: UploadVideoParams) => studyDepartmentApi.updateVideoStatus(params)

  private getHandleUploadSuccess = (id: number, type: PublicStudentVideoTypes, onSuccess?: () => void) => () => {
    onSuccess?.()

    const updatedVideos = (
      type === PublicStudentVideoTypes.Pedagogical ? this.uploadingPedagogicalVideos : this.uploadingTechnicalVideos
    )?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          currentStatus: VideoStatus.Waiting,
        }
      }

      return item
    })

    if (type === PublicStudentVideoTypes.Pedagogical) {
      this.setUploadingPedagogicalVideos(updatedVideos)
    } else {
      this.setUploadingTechnicalVideos(updatedVideos)
    }

    this.updateVideoStatus({ id, data: { message: mainDictionary.waitingMessage, status: VideoStatus.Waiting } })
  }

  runUploadFile = ({
    id,
    uploadFile,
    onSuccess,
    uploadUrl,
    type,
  }: {
    id: number
    uploadFile: any
    uploadUrl: string
    onSuccess?: () => void
    type: PublicStudentVideoTypes
  }) => {
    const callbacksObject = uploadToKinescopeFile({
      file: uploadFile,
      uploadUrl,
      onProgress: this.getHandleUploadProgress(id, type),
      onError: this.getHandleUploadError(id, type),
      onSuccess: this.getHandleUploadSuccess(id, type, onSuccess),
      origName: uploadFile.origName,
      id,
    })

    const handleAbort = () => callbacksObject.abort()

    const handleStart = () => callbacksObject.start()

    const updatedVideos = (
      type === PublicStudentVideoTypes.Pedagogical ? this.uploadingPedagogicalVideos : this.uploadingTechnicalVideos
    )?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          abort: handleAbort,
          start: handleStart,
        }
      }

      return item
    })

    if (type === PublicStudentVideoTypes.Pedagogical) {
      this.setUploadingPedagogicalVideos(updatedVideos)
    } else {
      this.setUploadingTechnicalVideos(updatedVideos)
    }
  }

  private getHandleUploadCurriculumVideosProgress =
    (id: number) => (bytesUploaded: number, bytesTotal: number, percentage: number) => {
      const updatedVideos = this.uploadingCurriculum?.videos?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            percentage,
          }
        }

        return item
      })

      this.setUploadingCurriculum({
        ...this.uploadingCurriculum!,
        videos: updatedVideos || [],
      })
    }

  private getHandleCurriculumVideoUploadError = (id: number) => (error: DetailedError | Error) => {
    const updatedVideos = this.uploadingCurriculum?.videos?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          currentStatus: VideoStatus.Error,
        }
      }

      return item
    })

    this.setUploadingCurriculum({
      ...this.uploadingCurriculum!,
      videos: updatedVideos || [],
    })

    appStore.sendLog({
      text: `
            *error*: ${error?.message || error?.stack || ''}
          `,
    })
  }

  private getHandleUploadCurriculumVideoSuccess = (id: number, onSuccess?: () => void) => () => {
    onSuccess?.()

    const updatedVideos = this.uploadingCurriculum?.videos?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          currentStatus: VideoStatus.Waiting,
        }
      }

      return item
    })

    this.setUploadingCurriculum({
      ...this.uploadingCurriculum!,
      videos: updatedVideos || [],
    })

    api.updateVideoStatus({ id, message: mainDictionary.waitingMessage, status: VideoStatus.Waiting })
  }

  runUploadingCurriculumFile = ({
    id,
    uploadFile,
    onSuccess,
    uploadUrl,
  }: {
    id: number
    uploadFile: any
    uploadUrl: string
    onSuccess?: () => void
  }) => {
    const callbacksObject = uploadToKinescopeFile({
      file: uploadFile,
      uploadUrl,
      onProgress: this.getHandleUploadCurriculumVideosProgress(id),
      onError: this.getHandleCurriculumVideoUploadError(id),
      onSuccess: this.getHandleUploadCurriculumVideoSuccess(id, onSuccess),
      origName: uploadFile.origName,
      id,
    })

    const handleAbort = () => callbacksObject.abort()

    const handleStart = () => callbacksObject.start()

    const updatedVideos = this.uploadingCurriculum?.videos?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          abort: handleAbort,
          start: handleStart,
        }
      }

      return item
    })

    this.setUploadingCurriculum({
      ...this.uploadingCurriculum!,
      videos: updatedVideos || [],
    })
  }
}

export const studyDepartment = new StudyDepartment()
