import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/lib'
import { DetailedError, Upload } from 'tus-js-client'
import { Endpoints, stage } from '../api/endpoints'
import { IUpload, uploadsApi } from '../api/upload'
import { calcUploadChunkSize } from './general'

const UPLOAD_URL = `${stage.apiUrl}${Endpoints.Tus}`

const uploadHomeworkFileHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
}

interface IProps {
  file: File
  onError?: (error: DetailedError | Error, file: UploadFile) => void
  onProgress?: (percentage: number, uid: string) => void
  onSuccess?: (file: UploadFile, url: string) => void
  origName: string
  url: string
}

export const uploadHomeworkFiles = ({ file, onError, onSuccess, onProgress, origName, url }: IProps) => {
  let lastOffset: number | null = null

  const upload = new Upload(file, {
    addRequestId: true,
    chunkSize: calcUploadChunkSize(file.size) * 1024 * 1024,
    headers: uploadHomeworkFileHeaders,
    removeFingerprintOnSuccess: true,
    retryDelays: [1000, 200000, 300000, 500000],
    endpoint: UPLOAD_URL,
    metadata: {
      filename: origName,
      filetype: file.type,
      id: url,
      'Content-Type': file.type,
    },

    onError: (error) => {
      // @ts-ignore
      onError?.(error, file)

      console.error(error)
    },

    onProgress: (bytesUploaded, bytesTotal) => {
      const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)

      // @ts-ignore
      onProgress?.(parseInt(percentage, 10), file?.uid)
    },

    onSuccess: () => {
      // @ts-ignore
      onSuccess?.(file, url)
    },
  })

  upload.findPreviousUploads().then((previousUploads) => {
    if (previousUploads.length && previousUploads[0].metadata.id === url) {
      upload.resumeFromPreviousUpload(previousUploads[0])
    }

    upload.start()
  })

  const handleAbort = () => {
    // @ts-ignore
    lastOffset = upload._offset

    return upload.abort()
  }

  const handleStart = () => {
    if (lastOffset) {
      // @ts-ignore
      upload._offset = lastOffset
      lastOffset = null
    }

    upload.start()
  }

  return {
    abort: handleAbort,
    start: handleStart,
  }
}

export const uploadFile = async (file: RcFile): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append('files', file)

  const { data } = await uploadsApi.uploadNotificationBanner(formData)

  return data
}

export const uploadEventFile = async (file: RcFile): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append('files', file)

  const { data } = await uploadsApi.uploadEventBanner(formData)

  return data
}

export const uploadAcademicRecordedVoiceFile = async (file: RcFile): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append('files', file)

  const { data } = await uploadsApi.uploadAcademicVoiceRecordFiles(formData)

  return data
}
