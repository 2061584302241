import { ResponseType } from 'axios'
import { StudentSystemStatus } from '@/api/controls'
import { GroupStatus } from '@/api/gamification'
import { OnDownloadProgressType } from '../groups/types'

export interface IChangeLoginRequest {
  newLogin: string
}

export interface IChangeStudentStatus {
  id: number
  data: {
    status: StudentSystemStatus
  }
}

export interface IGetStudentGroups {
  studentId: number
  key?: string
  page?: number
  perPage?: number
  status?: GroupStatus
}

export type KeyType = string[]

export type TStudentInfo = {
  data: {
    photo: string
  }
  id: number
}

export type StudentPrivateInfo = {
  pnfl?: string
  passportNumber?: string
  passportSeria?: string
  birthCertificateSeria?: string
  birthCertificateNumber?: string
  mobile?: string
  birthday?: string
  firstName?: string
  lastName?: string
  middleName?: string
  genderType?: number
  age?: number
}

export type ChangeStudentPrivateInfoReq = {
  id: number
  studentInfo: StudentPrivateInfo
}

export type downloadOptionType = {
  responseType: ResponseType
  onDownloadProgress: OnDownloadProgressType
}

export const enum StudentStatuses {
  FINISHED = 'Bitirgan',
  ACTIVE = "O'qiyabdi",
}

export type GetStudentCertificateReq = {
  studentId: number
}

export type ChangeCertificateParams = {
  id: string
  data: {
    isConcealed: boolean
  }
}

export type GetScheduleInfoParams = {
  studentId: number
  staffScheduleItemId: number
}

export type StudentId = {
  id: number
}

export enum ContractOwner {
  Self = 'self',
  Agent = 'agent',
}

export type SelfContractData = {
  contractId: number
  studentId: number
  signatory: ContractOwner
  phoneNumber: string
  passportSeria: string
  passportNumber: string
  pinfl: string
  givenFrom: string
  address: string
  agentWhoIs: string
  agentPhone: string
  agentName: string
}

export type ResendContractParams = {
  id: number
}
