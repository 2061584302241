import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { App } from '@/App'

Sentry.init({
  dsn: 'https://0ee09c03c9d4d3171deb87547abee89c@o4508811158159360.ingest.de.sentry.io/4508811160649808',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/najottalim\.io\/api/],
})

const domElement = document.getElementById('root')!

ReactDOM.createRoot(domElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
