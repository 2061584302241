export const gamificationOfferDictionary = {
  publicOfferingNotAdded: `Oferta qo'shilmagan`,
  lastUpdatedTime: (time: string) => `Oxirgi o'zgartirilgan vaqti: ${time}`,
  update: `O'zgartirish`,
  save: 'Saqlash',
  gamificationOffer: 'Gamification oferta',
  copyToClipboard: 'Nusxa olish',
  copiedToClipboard: 'Vaqtinchalik xotiraga nusxalandi',
  failedToClipboard: 'Vaqtinchalik xotiraga nusxalashda xatolik',
  studentStatuses: "O'quvchi statuslari",
  integrations: 'Integratsiyalar',
}
