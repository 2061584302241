import { ReactNode, Suspense } from 'react'
import { Loading } from '@/components'
import { NotFound } from '@/components/NotFound'
import { permissionObjectTypesValues, ValueOfPermissionObjectTypes } from '@/modules/permission'
import { useStores } from '@/stores'

type Props = {
  permissionName?: ValueOfPermissionObjectTypes
  children: ReactNode
}

export const AvailableComponent = ({ permissionName, children }: Props): ReactNode => {
  const { permissionsStore } = useStores()

  const permissionObjects = Object.keys(permissionsStore.structuredPermissions || {})

  if (
    permissionName &&
    (!permissionObjectTypesValues.includes(permissionName) || !permissionObjects.includes(permissionName))
  ) {
    return <NotFound />
  }

  return <Suspense fallback={<Loading />}>{children}</Suspense>
}
