import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form as AntForm, Input } from 'antd'
import classnamesBind from 'classnames/bind'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { addCatchNotification } from '@/modules/notifications'
import { requestPermission } from '@/shared/helpers'
import { useStores } from '@/stores'
import { storage } from '@/utils'
import { IFilterValues } from './types'

import styles from './login.module.scss'

const cn = classnamesBind.bind(styles)

export const Form = observer(() => {
  const { authStore } = useStores()
  const navigate = useNavigate()
  const [inProgress, setInProgress] = useState(false)
  const [form] = AntForm.useForm()

  const handleSubmit = async ({ login, password }: IFilterValues) => {
    const loginField = login && login.trim()
    const passwordField = password && password.trim()

    if (!loginField || !passwordField) {
      return null
    }

    const token = await requestPermission()

    setInProgress(true)

    authStore
      .getSignIn({ login: loginField, password: passwordField, fcmToken: token })
      .then((res) => {
        if (res.success) {
          navigate(ROUTES.home)
        }

        storage.set('fcmToken', token || '')
      })
      .catch(addCatchNotification)
      .finally(() => setInProgress(false))

    return null
  }

  const handleClick = () => {
    form.submit()
  }

  return (
    <div className={cn('login__form')}>
      <AntForm onFinish={handleSubmit}>
        <AntForm.Item name="login" rules={[{ required: true }]}>
          <Input size="large" placeholder={mainDictionary.login} prefix={<UserOutlined />} />
        </AntForm.Item>

        <AntForm.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            size="large"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            prefix={<LockOutlined />}
            placeholder={mainDictionary.secretKey}
          />
        </AntForm.Item>

        <div className={cn('login__form-submit')}>
          <Button onClick={handleClick} type="primary" size="large" htmlType="submit" block disabled={inProgress}>
            {mainDictionary.login}
          </Button>
        </div>
      </AntForm>
    </div>
  )
})
