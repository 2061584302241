import { Endpoints, INetworkConfig, Instance, IResponse, ISuccessResponse } from '@/api'
import { IStaffInfo } from '@/stores/auth/types'
import {
  ChangeOpenAiSettings,
  ChangeStudentReq,
  ContractFormValues,
  EditContractReq,
  EditStudentReq,
  GetContractHistoryReq,
  GetContractsAll,
} from '@/stores/settings'
import { MobileAppVersionReq, MobileAppVersionRes } from '@/types'
import { SettingsEndpoints } from './endpoints'
import {
  Contract,
  ContractHistoryRes,
  ContractRes,
  GamificationOfferingParams,
  GamificationOfferingResponseTypes,
  Integration,
  StudentStatus,
  StudentStatusesRes,
} from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class SettingsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  changeStudentStatus = (params: ChangeStudentReq): Promise<IResponse<StudentStatus>> =>
    this.put(`${SettingsEndpoints.ControlActions}${SettingsEndpoints.UpdateStudentGroupActionControl}`, params)

  getGamificationOffering = (): Promise<IResponse<GamificationOfferingResponseTypes>> =>
    this.get(SettingsEndpoints.SettingsGamificationOffering)

  getStudentStatuses = (): Promise<IResponse<StudentStatusesRes>> => this.get(SettingsEndpoints.ControlActions)

  getStudentStatus = (id: number): Promise<IResponse<StudentStatus>> =>
    this.get(`${SettingsEndpoints.ControlActions}/${id}`)

  editStudentStatus = (info: EditStudentReq): Promise<IResponse<StudentStatus>> =>
    this.put(`${SettingsEndpoints.ControlActions}/${info.id}`, info.data)

  updateGamificationOffering = (params: GamificationOfferingParams): Promise<IResponse<IStaffInfo>> =>
    this.put(SettingsEndpoints.SettingsGamificationOfferingUpdate, params)

  getContracts = (params: GetContractsAll): Promise<IResponse<ContractRes>> =>
    this.get(Endpoints.ContractsAll, { params })

  addContract = (params: ContractFormValues): Promise<IResponse<ContractRes>> =>
    this.post(Endpoints.ContractsCreate, params)

  editContract = (params: EditContractReq): Promise<IResponse<ContractRes>> =>
    this.put(`${Endpoints.Contracts}/${params.id}`, params.data)

  getContractHistory = (params: GetContractHistoryReq): Promise<IResponse<ContractHistoryRes>> =>
    this.get(`${Endpoints.Contracts}/${params.id}${Endpoints.History}`, { params: params.params })

  getContract = (params: number): Promise<IResponse<Contract>> => this.get(`${Endpoints.Contracts}/${params}`)

  getMobileAppVersions = (params: Partial<MobileAppVersionReq>): Promise<IResponse<MobileAppVersionRes>> =>
    this.get(Endpoints.MobileAppVersions, { params })

  addNewMobileAppVersion = (params: MobileAppVersionReq): Promise<IResponse<ISuccessResponse>> =>
    this.post(SettingsEndpoints.CreateMobileAppVersion, params)

  editOpenAiSettings = (params: ChangeOpenAiSettings): Promise<IResponse<ISuccessResponse>> =>
    this.put(SettingsEndpoints.Integrations, params)

  getIntegrations = (): Promise<IResponse<Integration[]>> => this.get(SettingsEndpoints.Integrations)
}

export const settingsApi = new SettingsApi(config)
